import { Component, OnInit, Input, ViewChild, SimpleChange } from '@angular/core';
import { SwiperDirective, SwiperConfigInterface, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Settings, AppSettings } from '../../app.settings';

import { AppService } from '../../app.service';
import { CompareOverviewComponent } from '../compare-overview/compare-overview.component';
import { ContractRes, PropertyResponse } from 'src/app/models/models';
import { environment } from 'src/environments/environment';
import { PropertyService } from 'src/app/services/property.service';

@Component({
  selector: 'app-property-item',
  templateUrl: './property-item.component.html',
  styleUrls: ['./property-item.component.scss'],
})
export class PropertyItemComponent implements OnInit {
  @Input() property: PropertyResponse;
  @Input() viewType: string = 'grid';
  @Input() viewColChanged: boolean = false;
  @Input() fullWidthPage: boolean = true;
  public column: number = 4;
  // public address:string;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  public config: SwiperConfigInterface = {};
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
  };
  public settings: Settings;
  public urlBack = environment.urlBack;
  public contractLabel: string;
  public contract: ContractRes;
  constructor(public appSettings: AppSettings, public appService: AppService, public propService: PropertyService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.contract = this.property?.contracts.find((contract) => contract.state.id == 1);
    if (this.contract) {
      this.contractLabel = this.contract.type.id == 2 ? 'rent' : this.contract.type.id == 1 ? 'sale' : null;
    }
  }

  ngAfterViewInit() {
    this.initCarousel();
    // this.appService.getAddress(this.property.location.lat, this.property.location.lng).subscribe(data=>{
    //   console.log(data['results'][0]['formatted_address']);
    //   this.address = data['results'][0]['formatted_address'];
    // })
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes.viewColChanged) {
      this.getColumnCount(changes.viewColChanged.currentValue);
      if (!changes.viewColChanged.isFirstChange()) {
        if (this.property.photos.length > 1) {
          this.directiveRef.update();
        }
      }
    }

    for (let propName in changes) {
      let changedProp = changes[propName];
      if (!changedProp.isFirstChange()) {
        if (this.property.photos.length > 1) {
          this.initCarousel();
          this.config.autoHeight = true;
          this.directiveRef.update();
        }
      }
    }
  }

  public getColumnCount(value) {
    if (value == 25) {
      this.column = 4;
    } else if (value == 33.3) {
      this.column = 3;
    } else if (value == 50) {
      this.column = 2;
    } else {
      this.column = 1;
    }
  }

  public getStatusBgColor(status) {
    switch (status) {
      case 'Para Venta':
        return '#558B2F';
      case 'Para Renta':
        return '#1E88E5';
      case 'Open House':
        return '#009688';
      case 'No Fees':
        return '#FFA000';
      case 'Hot Offer':
        return '#F44336';
      case 'Sold':
        return '#000';
      default:
        return '#01579B';
    }
  }

  public initCarousel() {
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,
      keyboard: false,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,
      loop: true,
      preloadImages: false,
      lazy: true,
      nested: true,
      // autoplay: {
      //   delay: 5000,
      //   disableOnInteraction: false
      // },
      speed: 500,
      effect: 'slide',
    };
  }

  public addToCompare() {
    this.propService.addToCompare(this.property, CompareOverviewComponent, this.settings.rtl ? 'rtl' : 'ltr');
  }

  public onCompare() {
    return this.propService.Data.compareList.filter((item) => item.id == this.property.id)[0];
  }

  public addToFavorites() {
    this.propService.addToFavorites(this.property, this.settings.rtl ? 'rtl' : 'ltr');
  }

  public onFavorites() {
    return this.propService.Data.favorites.filter((item) => item.id == this.property.id)[0];
  }
}
